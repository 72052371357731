.form{
    display: flex;
    padding: 0 1rem 0;
    label{
        font-weight: 200;
    }
}

.calculator{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    text-align: center;
    margin: 1rem 0 1rem;
}

.submit{
    margin: 1rem; 
}

.opciones{
    display: flex;
    align-items: center;
    label{
        width: 6rem;
    }
}

.form-check-input{
    background-size: contain;
    appearance: none;
    border: 1px solid rgba(0,0,0,.25);
    height: 2em;
    margin: 1rem !important;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    float: left;
    width: 4em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
    &:checked{
        background-position: right center;
    }
    cursor:pointer;
}

.opcion-selected{
    color: rgb(237 223 213);
    font-weight: 700 !important;
}

@media all and (max-width: 1200px) {
    .form{
        flex-direction: column;
        padding: 0 1rem 0;
    }
    .calculator{
        width: auto;
    }
}

@media all and (max-width: 600px){
    .calculator{
        margin: 1rem 0 0;
    }
}