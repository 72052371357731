.dollar {
    margin-top: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    background-color: rgb(233, 233, 233);
    padding: 1rem;
  }
  
  @media all and (max-width: 1200px) {
    .dollar {
      margin-bottom: 3rem;
      z-index: 3;
      height: 3rem;
      padding: 0.5rem 1rem 0.5rem;
      color: white;
      background-color: rgb(58, 100, 158);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      cursor: pointer;
      h3 {
        font-weight: 400;
      }
    }
  }
  