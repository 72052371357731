@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;500;600;700&display=swap');



.App {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(237 223 213);
  height: 100vh;
  color: rgb(58, 100, 158);
}

body{
  *{
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

input{
  text-align: center;
  font-family: 'DM Sans';
  font-weight: 600;
  border: none;
  box-shadow: 2px 7px 32px 0px rgba(0,0,0,0.35);
  outline: none;
  height: 2rem;
  border-radius: 1rem;
  border: 1px solid transparent;
  &:active, &:focus{
    border: 1px solid rgba(58, 100, 158, .5);
    &::placeholder{
      opacity: 0;
    }
  }
  &::placeholder{
    text-align: center;
  }
}

h1{
  margin-bottom: 1rem;
}

.stonks {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.stonks svg {
  height: 500px;
  width: 500px;
  .s0 {
    fill: #ffffff;
  }
  .s1 {
    fill: #a9d9f3;
  }
  .s2 {
    fill: none;
    stroke: #3169a7;
    stroke-width: 2;
  }
  .s3 {
    fill: #fad7dc;
  }
  .s4 {
    fill: #353c73;
  }
  .s5 {
    fill: #85b0d3;
  }
  .s6 {
    fill: none;
    stroke: #353c73;
    stroke-width: 4;
  }
  .s7 {
    fill: #3169a7;
  }
  .s8 {
    fill: #fbadb1;
  }
  .s9 {
    fill: none;
    stroke: #353c73;
    stroke-width: 2;
  }
  .s10 {
    fill: none;
    stroke: #353c73;
    stroke-width: 2;
    stroke-dasharray: 12, 12;
  }
  .s11 {
    fill: none;
    stroke: #ffffff;
    stroke-width: 2;
  }
  .s12 {
    fill: none;
    stroke: #353c73;
    stroke-width: 3;
  }
}

.tarjeta {
  position: absolute;
  right: 0;
  top: 0;
  svg {
    height: 500px;
    width: 500px;
    .s0 {
      fill: none;
      stroke: #d3d1e8;
      stroke-width: 2;
    }
    .s1 {
      fill: #e3e2ef;
    }
    .s2 {
      fill: #b4b4d5;
    }
    .s3 {
      fill: #d3d1e8;
    }
    .s4 {
      fill: #f67406;
    }
    .s5 {
      fill: #401a4c;
    }
    .s6 {
      fill: #ffaa24;
    }
    .s7 {
      fill: #e3baa1;
    }
    .s8 {
      fill: #356fe4;
    }
    .s9 {
      fill: #e39fa1;
    }
    .s10 {
      fill: none;
      stroke: #401a4c;
      stroke-width: 0.6;
    }
    .s11 {
      fill: #f1f1f1;
    }
    .s12 {
      fill: #f67406;
      stroke: #f1f1f1;
      stroke-width: 0.6;
    }
    .s13 {
      fill: #5986e4;
    }
    .s14 {
      fill: #ffffff;
    }
    .s15 {
      fill: #f6921e;
    }
    .s16 {
      fill: #562466;
    }
    .s17 {
      fill: none;
      stroke: #356fe4;
      stroke-width: 2;
    }
    .s18 {
      fill: none;
      stroke: #e3e2ef;
      stroke-width: 2;
    }
  }
}

.dot {
  position: absolute;
  left: 0;
  top: 0;
  svg {
    .s0 {
      fill: #e58d05;
    }
    .s1 {
      fill: #fc3e00;
    }
    .s2 {
      fill: #5073b3;
    }
  }
}

.bar{
  position: absolute;
  right: 20%;
  bottom: 0;
  svg{
    .s0 { fill: #5073b3 } 
  }
}


.firma{
  position: absolute;
  bottom: 1%;
  right: 0;
  z-index: 1;
  a{
    all: unset;
    padding-right: 1rem;
    cursor: pointer;
  }
}

@media all and (max-width: 600px){
  .App{
    padding: 1rem 0 1rem;
    overflow: auto;
    height: auto;
    h1{
      z-index: 3;
    }
    font-size: 12px;
    input{
      margin: 1rem 0 1rem;
    }
    p{
      margin-bottom: 1rem;
    }
    position: relative;
  }
  .dot{
    top: 10%;
  }
  .tarjeta{
    top: 30%
  }

  .firma{
    right: 0;
    position: absolute;
    text-align: center;
    bottom: 0;
    background-color: rgb(237 223 213);
    width: 100%;
    padding: 1rem 0 1rem;
  }
  
}

@media all and (max-width: 1200px) {
  .App{
    padding: 1rem 0 1rem;
    overflow: auto;
    height: auto;
    h1{
      z-index: 3;
    }
    font-size: 12px;
    input{
      margin: 1rem 0 1rem;
    }
    p{
      margin-bottom: 1rem;
    }
    position: relative;
  }
  .dot{
    top: 10%;
  }
  .tarjeta{
    top: 30%
  }

  .firma{
    right: 0;
    position: absolute;
    text-align: center;
    bottom: 0;
    background-color: rgb(237 223 213);
    width: 100%;
    padding: 1rem 0 1rem;
  }
}

@media all and (max-height: 900px){
  
  svg{
    height: 300px !important;
    width: 300px !important;
  }
}

@media all and (min-width: 1980px){
  .App{
    font-size: 20px;
  }
  .stonks{
    left: 15%;
    svg{
      height: 600px;
      width: 600px;
    }
  }
}