.resultado{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    margin: 1rem 0 1rem;
    
}

.numeros{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.minititle{
    margin-bottom: 2rem;
}


.texto{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50%;
    text-align: justify;
    // height: 10rem;
    // width: 40%;
    margin-top: 1rem;
    background-color: rgb(233, 233, 233);
    border-radius: 1rem;
    color: rgb(58, 100, 158);
    box-shadow: 2px 7px 32px 0px rgba(0,0,0,0.35);
    padding: 1rem;
}

@media all and (max-width: 1200px) {
    .resultado{
        width: auto;
        flex-direction: column-reverse;
    }
    .texto{
        margin: 0 0 1rem;
    }
}

@media all and (max-width: 600px){
    .minititle{
        margin-bottom: 1rem;
        text-align: center;
    }
    .tasa-interes{
        display: none;
    }
    .resultado{
        margin: 0 0 1rem;
    }
}