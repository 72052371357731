@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;500;600;700&display=swap");
.App {
  font-family: "Poppins";
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(237, 223, 213);
  height: 100vh;
  color: rgb(58, 100, 158);
}

body * {
  margin-block-start: 0;
  margin-block-end: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield; /* Firefox */
}

input {
  text-align: center;
  font-family: "DM Sans";
  font-weight: 600;
  border: none;
  box-shadow: 2px 7px 32px 0px rgba(0, 0, 0, 0.35);
  outline: none;
  height: 2rem;
  border-radius: 1rem;
  border: 1px solid transparent;
}
input:active, input:focus {
  border: 1px solid rgba(58, 100, 158, 0.5);
}
input:active::-moz-placeholder, input:focus::-moz-placeholder {
  opacity: 0;
}
input:active::placeholder, input:focus::placeholder {
  opacity: 0;
}
input::-moz-placeholder {
  text-align: center;
}
input::placeholder {
  text-align: center;
}

h1 {
  margin-bottom: 1rem;
}

.stonks {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.stonks svg {
  height: 500px;
  width: 500px;
}
.stonks svg .s0 {
  fill: #ffffff;
}
.stonks svg .s1 {
  fill: #a9d9f3;
}
.stonks svg .s2 {
  fill: none;
  stroke: #3169a7;
  stroke-width: 2;
}
.stonks svg .s3 {
  fill: #fad7dc;
}
.stonks svg .s4 {
  fill: #353c73;
}
.stonks svg .s5 {
  fill: #85b0d3;
}
.stonks svg .s6 {
  fill: none;
  stroke: #353c73;
  stroke-width: 4;
}
.stonks svg .s7 {
  fill: #3169a7;
}
.stonks svg .s8 {
  fill: #fbadb1;
}
.stonks svg .s9 {
  fill: none;
  stroke: #353c73;
  stroke-width: 2;
}
.stonks svg .s10 {
  fill: none;
  stroke: #353c73;
  stroke-width: 2;
  stroke-dasharray: 12, 12;
}
.stonks svg .s11 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
}
.stonks svg .s12 {
  fill: none;
  stroke: #353c73;
  stroke-width: 3;
}

.tarjeta {
  position: absolute;
  right: 0;
  top: 0;
}
.tarjeta svg {
  height: 500px;
  width: 500px;
}
.tarjeta svg .s0 {
  fill: none;
  stroke: #d3d1e8;
  stroke-width: 2;
}
.tarjeta svg .s1 {
  fill: #e3e2ef;
}
.tarjeta svg .s2 {
  fill: #b4b4d5;
}
.tarjeta svg .s3 {
  fill: #d3d1e8;
}
.tarjeta svg .s4 {
  fill: #f67406;
}
.tarjeta svg .s5 {
  fill: #401a4c;
}
.tarjeta svg .s6 {
  fill: #ffaa24;
}
.tarjeta svg .s7 {
  fill: #e3baa1;
}
.tarjeta svg .s8 {
  fill: #356fe4;
}
.tarjeta svg .s9 {
  fill: #e39fa1;
}
.tarjeta svg .s10 {
  fill: none;
  stroke: #401a4c;
  stroke-width: 0.6;
}
.tarjeta svg .s11 {
  fill: #f1f1f1;
}
.tarjeta svg .s12 {
  fill: #f67406;
  stroke: #f1f1f1;
  stroke-width: 0.6;
}
.tarjeta svg .s13 {
  fill: #5986e4;
}
.tarjeta svg .s14 {
  fill: #ffffff;
}
.tarjeta svg .s15 {
  fill: #f6921e;
}
.tarjeta svg .s16 {
  fill: #562466;
}
.tarjeta svg .s17 {
  fill: none;
  stroke: #356fe4;
  stroke-width: 2;
}
.tarjeta svg .s18 {
  fill: none;
  stroke: #e3e2ef;
  stroke-width: 2;
}

.dot {
  position: absolute;
  left: 0;
  top: 0;
}
.dot svg .s0 {
  fill: #e58d05;
}
.dot svg .s1 {
  fill: #fc3e00;
}
.dot svg .s2 {
  fill: #5073b3;
}

.bar {
  position: absolute;
  right: 20%;
  bottom: 0;
}
.bar svg .s0 {
  fill: #5073b3;
}

.firma {
  position: absolute;
  bottom: 1%;
  right: 0;
  z-index: 1;
}
.firma a {
  all: unset;
  padding-right: 1rem;
  cursor: pointer;
}

@media all and (max-width: 600px) {
  .App {
    padding: 1rem 0 1rem;
    overflow: auto;
    height: auto;
    font-size: 12px;
    position: relative;
  }
  .App h1 {
    z-index: 3;
  }
  .App input {
    margin: 1rem 0 1rem;
  }
  .App p {
    margin-bottom: 1rem;
  }
  .dot {
    top: 10%;
  }
  .tarjeta {
    top: 30%;
  }
  .firma {
    right: 0;
    position: absolute;
    text-align: center;
    bottom: 0;
    background-color: rgb(237, 223, 213);
    width: 100%;
    padding: 1rem 0 1rem;
  }
}
@media all and (max-width: 1200px) {
  .App {
    padding: 1rem 0 1rem;
    overflow: auto;
    height: auto;
    font-size: 12px;
    position: relative;
  }
  .App h1 {
    z-index: 3;
  }
  .App input {
    margin: 1rem 0 1rem;
  }
  .App p {
    margin-bottom: 1rem;
  }
  .dot {
    top: 10%;
  }
  .tarjeta {
    top: 30%;
  }
  .firma {
    right: 0;
    position: absolute;
    text-align: center;
    bottom: 0;
    background-color: rgb(237, 223, 213);
    width: 100%;
    padding: 1rem 0 1rem;
  }
}
@media all and (max-height: 900px) {
  svg {
    height: 300px !important;
    width: 300px !important;
  }
}
@media all and (min-width: 1980px) {
  .App {
    font-size: 20px;
  }
  .stonks {
    left: 15%;
  }
  .stonks svg {
    height: 600px;
    width: 600px;
  }
}/*# sourceMappingURL=App.css.map */