.card{
    z-index: 2;
    width: 70%;
    background-color: rgb(58, 100, 158);
    border-radius: 1rem;
    color: white;
    box-shadow: 1px 10px 33px -4px rgba(0,0,0,0.39);
}

@media all and (min-width: 1200px) {
    .card{
        height: 30rem;
    }
    
}

@media all and (min-width: 1980px){
    .card{
        width: 80rem;
    }
}
