.minicard{
    height: 10rem;
    width: 40%;
    background-color: rgb(233, 233, 233);
    border-radius: 1rem;
    color: rgb(58, 100, 158);
    box-shadow: 2px 7px 32px 0px rgba(0,0,0,0.35);
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

@media all and (max-width: 600px){
    .minicard{
        width: 30%;
        font-size: 12px;
        height: 5rem;
        text-align: center;
    }
}
